<template>
  <data-table v-if="relationField"
              @createOptions="createNewOptions"
              :entity-type="formattedModuleName"
              :has-filters="false"
              :ref="`field_${fieldProps.name}_${fieldProps.id}`"
              :create-new-option="fieldProps.create_new_data_option"
              :dtFilters="dtFilters"
              :data-filters="dataFilters"
              :field-props="fieldProps"
              class="expand-field"
              is-datatable-field
  />
</template>

<script>
import FormFieldMixin from "@/modules/erp_entities/mixins/FormFieldMixin"
import DataTable from "@/modules/erp_entities/components/page/table/DataTable.vue"
import { getRelationField } from "@/utilities/helper"
import bus from "@/modules/erp_entities/utilities/eventBus"

const pluralize = require('pluralize')

export default {
  name: "DataTableField",
  mixins: [FormFieldMixin],
  props: {
    dataFilters: {
      required: false,
    },
  },
  components: {
    DataTable,
  },
  data() {
    return {
      relationField: false,
      visible: false,
      key: 0,
    }
  },
  computed: {
    formattedModuleName(){
      return pluralize(this.fieldProps.data_module).toKebabCase()
    },
    dtFilters() {
      return [
        {
          prop: this.relationField,
          compare: 'in',
          value: [this.$route.params.id || 0 ],
        },
      ]
    },
  },
  async created() {

    // hot fix rock subcontracts
    if (this.$route.params.entityType === 'contracts' && this.fieldProps.name === 'subcontracts') {
      this.relationField = 'parent_id'

    // default behavior
    } else {
      this.relationField = await getRelationField(this.$route.params.entityType, this.fieldProps.data_module)
    }
  },
  mounted() {
    bus.$on(`record_created_${this.fieldProps.name}_${this.fieldProps.id}`, () => {
      this.$refs[`field_${this.fieldProps.name}_${this.fieldProps.id}`]?.loadData()
    })
  },
  methods: {
    createNewOptions() {
      this.$modal.show('create_new_entity')
      this.$emit('createOptions', this.fieldProps)
    },
  },
}
</script>